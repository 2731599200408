import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#cb2c30',
        secondary: '#cb2c30',
        accent: '#cb2c30',
        error: '#c30000',
        info: '#00a5ff',
        success: '#00c51d',
        warning: '#ecbd00'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
