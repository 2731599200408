import { email, required, numeric } from 'vee-validate/dist/rules';
import { setInteractionMode } from 'vee-validate';
import { extend } from 'vee-validate';
import i18n from './i18n.js';

setInteractionMode('lazy');

// INSTALLED RULES
extend('required', { ...required });
extend('email', { ...email });
extend('numeric', { ...numeric });

export const initializeCheckBoxValidator = language => {
    extend('isTrue', {
        ...required,
        message: i18n.t('VALIDATION.ERROR.MUST_BE_CHECKED', language).toString(),
    });
};