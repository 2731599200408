var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-overlay',{attrs:{"z-index":"999","value":_vm.isLoading,"color":"white"}},[_c('div',{staticClass:"flex-column align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"60","width":"3","color":"#cb2c30"}})],1)]),(!_vm.successMessage)?_c('div',[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"grid grid-cols-12 lg:-mx-4 px-4 py-8 bg-primary-white gap-y-4"},[_c('div',{staticClass:"col-span-12 md:col-span-6 lg:col-span-3 px-gap"},[_c('ValidationProvider',{attrs:{"name":"salutation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsSalutation,"item-text":_vm.getTranslation,"label":_vm.translations['SALUTATION'],"error":errors.length > 0,"required":"","clearable":""},model:{value:(_vm.newsletterSignup.salutation),callback:function ($$v) {_vm.$set(_vm.newsletterSignup, "salutation", $$v)},expression:"newsletterSignup.salutation"}})]}}],null,false,1954633453)})],1),_c('div',{staticClass:"col-span-12 md:col-span-6 md:col-start-1 lg:col-span-3 lg:col-start-auto px-gap"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.translations['FIRST_NAME'],"error":errors.length > 0,"required":""},model:{value:(_vm.newsletterSignup.firstName),callback:function ($$v) {_vm.$set(_vm.newsletterSignup, "firstName", $$v)},expression:"newsletterSignup.firstName"}})]}}],null,false,397818167)})],1),_c('div',{staticClass:"col-span-12 md:col-span-6 lg:col-span-3 px-gap"},[_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.translations['LAST_NAME'],"error":errors.length > 0,"required":""},model:{value:(_vm.newsletterSignup.lastName),callback:function ($$v) {_vm.$set(_vm.newsletterSignup, "lastName", $$v)},expression:"newsletterSignup.lastName"}})]}}],null,false,192086519)})],1),_c('div',{staticClass:"col-span-12 md:col-span-6 lg:col-span-3 lg:col-start-1 px-gap"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.translations['E_MAIL'],"error":errors.length > 0,"required":""},model:{value:(_vm.newsletterSignup.email),callback:function ($$v) {_vm.$set(_vm.newsletterSignup, "email", $$v)},expression:"newsletterSignup.email"}})]}}],null,false,2494726615)})],1),_c('div',{staticClass:"col-span-12 md:col-span-6 lg:col-span-3 px-gap"},[_c('ValidationProvider',{attrs:{"name":"language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLanguage,"item-text":_vm.getTranslation,"label":_vm.translations['CHOOSE_LANGUAGE'],"error":errors.length > 0,"required":"","clearable":""},model:{value:(_vm.newsletterSignup.language),callback:function ($$v) {_vm.$set(_vm.newsletterSignup, "language", $$v)},expression:"newsletterSignup.language"}})]}}],null,false,1472398687)})],1),_c('div',{staticClass:"col-span-12 md:col-span-6 lg:col-span-3 px-gap"},[_c('ValidationProvider',{attrs:{"name":"interval","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsInterval,"item-text":_vm.getTranslation,"label":_vm.translations['INTERVAL'],"error":errors.length > 0,"required":"","multiple":"","clearable":""},model:{value:(_vm.newsletterSignup.interval),callback:function ($$v) {_vm.$set(_vm.newsletterSignup, "interval", $$v)},expression:"newsletterSignup.interval"}})]}}],null,false,2996858326)})],1),_c('div',{staticClass:"col-span-12 md:col-span-6 lg:col-span-3 px-gap"},[_c('button',{staticClass:"button w-full",on:{"click":function($event){return _vm.signup()}}},[_vm._v(_vm._s(_vm.translations['BUTTON_SIGNUP']))])]),(_vm.showErrorMessage)?_c('div',{staticClass:"flex-grow text-center pt-4 text-primary-red"},[_vm._v(_vm._s(_vm.translations['ERROR']))]):_vm._e()])])],1):_c('div',{staticClass:"mt-6 text-center font-bold",class:[ this.colorWhite ? 'text-primary-white' : 'text-primary-black' ]},[_vm._v(_vm._s(_vm.translations[_vm.successMessage]))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }