<template>
  <v-app>
    <v-overlay z-index="999" :value="isLoading" color="white">
      <div class="flex-column align-center">
        <v-progress-circular indeterminate size="60" width="3" color="#cb2c30" />
      </div>
    </v-overlay>
    <div v-if="!successMessage">
      <ValidationObserver ref="validator">
        <div class="grid grid-cols-12 lg:-mx-4 px-4 py-8 bg-primary-white gap-y-4">
          <div class="col-span-12 md:col-span-6 lg:col-span-3 px-gap">
            <ValidationProvider v-slot="{ errors }" name="salutation" rules="required">
              <v-select
                v-model="newsletterSignup.salutation"
                :items="itemsSalutation"
                :item-text="getTranslation"
                :label="translations['SALUTATION']"
                :error="errors.length > 0"
                required
                clearable
              ></v-select>
            </ValidationProvider>
          </div>
          <div class="col-span-12 md:col-span-6 md:col-start-1 lg:col-span-3 lg:col-start-auto px-gap">
            <ValidationProvider v-slot="{ errors }" name="firstName" rules="required">
              <v-text-field
                v-model="newsletterSignup.firstName"
                :label="translations['FIRST_NAME']"
                :error="errors.length > 0"
                required
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 px-gap">
            <ValidationProvider v-slot="{ errors }" name="lastName" rules="required">
              <v-text-field
                v-model="newsletterSignup.lastName"
                :label="translations['LAST_NAME']"
                :error="errors.length > 0"
                required
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 lg:col-start-1 px-gap">
            <ValidationProvider v-slot="{ errors }" name="email" rules="email|required">
              <v-text-field
                v-model="newsletterSignup.email"
                :label="translations['E_MAIL']"
                :error="errors.length > 0"
                required
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 px-gap">
            <ValidationProvider v-slot="{ errors }" name="language" rules="required">
              <v-select
                  v-model="newsletterSignup.language"
                  :items="itemsLanguage"
                  :item-text="getTranslation"
                  :label="translations['CHOOSE_LANGUAGE']"
                  :error="errors.length > 0"
                  required
                  clearable
              ></v-select>
            </ValidationProvider>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 px-gap">
            <ValidationProvider v-slot="{ errors }" name="interval" rules="required">
              <v-select
                  v-model="newsletterSignup.interval"
                  :items="itemsInterval"
                  :item-text="getTranslation"
                  :label="translations['INTERVAL']"
                  :error="errors.length > 0"
                  required
                  multiple
                  clearable
              ></v-select>
            </ValidationProvider>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 px-gap">
            <button @click="signup()" class="button w-full">{{ translations['BUTTON_SIGNUP'] }}</button>
          </div>
          <div v-if="showErrorMessage" class="flex-grow text-center pt-4 text-primary-red">{{ translations['ERROR'] }}</div>
        </div>
      </ValidationObserver>
    </div>
    <div v-else class="mt-6 text-center font-bold" :class="[ this.colorWhite ? 'text-primary-white' : 'text-primary-black' ]">{{ translations[successMessage] }}</div>
  </v-app>
</template>

<script>
import { VTextField, VRadioGroup } from 'vuetify/lib';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import newsletterService from '@/components/common/services/newsletterService';

const emptyNewsletterSignup = {
  salutation: null,
  firstName: null,
  lastName: null,
  email: null,
  interval: false,
  language: null
};

export default {
  name: 'NewsletterSignup',
  components: {
    ValidationProvider,
    ValidationObserver,
    VTextField,
    VRadioGroup
  },
  props: ['lang', 'colorWhite', 'translations'],
  data: () => ({
    newsletterSignup: [{ ...emptyNewsletterSignup }],
    showErrorMessage: false,
    successMessage: null,
    isLoading: false,
    itemsSalutation: [
      { text: 'FEMALE', value: 'female' },
      { text: 'MALE', value: 'male' },
      { text: 'OTHER', value: 'other' }
    ],
    itemsLanguage: [
      { text: 'GERMAN', value: 'de' },
      { text: 'FRENCH', value: 'fr' },
      { text: 'ENGLISH', value: 'en' }
    ],
    itemsInterval: [
      { text: 'SEASONAL', value: 'seasonal' },
      { text: 'WEEKLY', value: 'weekly' }
    ],
  }),
  mounted() {
    this.$i18n.locale = this.lang;
  },
  methods: {
    signup() {
      this.$refs.validator.validate().then(valid => {
        if (valid) {
          this.showErrorMessage = false;
          this.successMessage = null;
          this.isLoading = true;
          newsletterService
            .signupToNewsletter({
              salutation: this.newsletterSignup.salutation,
              firstName: this.newsletterSignup.firstName,
              lastName: this.newsletterSignup.lastName,
              email: this.newsletterSignup.email,
              interval: this.newsletterSignup.interval,
              language: this.newsletterSignup.language,
              [window.csrfTokenName]: window.csrfTokenValue
            })
            .then(() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({event: 'form_submit', formType: 'newsletter'});
              this.successMessage = 'SUCCESS';
              this.isLoading = false;
            })
            .catch(err => {
              if (err.response.status === 422) {
                this.successMessage = 'ALREADY_REGISTERED';
              } else {
                this.showErrorMessage = true;
              }
              this.isLoading = false;
            });
          this.isLoading = false;
        }
      });
    },
    getTranslation(item) {
      return this.$props.translations[item.text];
    }
  }
};
</script>
