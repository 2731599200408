import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from './../locales/de.json';
import fr from './../locales/fr.json';
import en from './../locales/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({ locale: 'de', fallbackLocale: 'de', messages: { de, fr, en } });

export default i18n;
