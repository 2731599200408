import axiosInstance from './httpService';

const basePathNewsletter = '/newsletter-subscription/signup';

const newsletterService = {
  signupToNewsletter(newsletterSignup) {
    return axiosInstance.post(basePathNewsletter, newsletterSignup);
  }
};

export default newsletterService;
